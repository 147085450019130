import { Controller } from '@hotwired/stimulus'
import { Popover, Tooltip } from 'bootstrap-5'

// This controller is much better at disposing of active popovers/tooltips when
// the underlying component gets removed through turbo frame actions and is
// therefore recommended (by me, at least) over using event listeners and
// standard bootstrap initialisers.
export default class extends Controller {
  connect() {
    if (this.element.matches('[data-bs-toggle="popover"]')) {
      this.popover = new Popover(this.element, { sanitizeFn: function(html) { return html }})
    }
    if (this.element.matches('[data-bs-toggle="tooltip"]')) {
      this.tooltip = new Tooltip(this.element, { sanitizeFn: function(html) { return html }})
    }
  }

  disconnect() {
    if (this.popover) {
      this.popover.dispose()
    }
    if (this.tooltip) {
      this.tooltip.dispose()
    }
  }

  click(event) {
    event.preventDefault()

    this.element.click()
  }
}

//
// TODO: This has a problem with not disposing and unloading popovers and tooltips
//   before rendering the new ones. I have not yet been able to figure out turbo
//   events to make this process reliable.
//
// import { Tooltip, Popover } from 'bootstrap-5';
//
// function fromSelector(parent, selector) {
//   return [].slice.call(parent.querySelectorAll(selector));
// }
//
// function withSelector(parent, selector, callback) {
//   return fromSelector(parent, selector).map(function (element) {
//     return new callback(element);
//   })
// }
//
// function initBootstrapComponents(element) {
//   withSelector(element, '[data-bs-toggle="tooltip"]', (e) => e['bs-tooltip'] = e['bs-tooltip'] || new Tooltip(e))
//   withSelector(element, '[data-bs-toggle="popover"]', (e) => e['bs-popover'] = e['bs-popover'] || new Popover(e))
// }
//
// function disableBootstrapComponents(element) {
//   console.log('disableBootstrapComponents', element)
//   withSelector(element, '[data-bs-toggle="tooltip"]', (e) => e['bs-tooltip'] && e['bs-tooltip'].dispose())
//   withSelector(element, '[data-bs-toggle="popover"]', (e) => e['bs-tooltip'] && e['bs-popover'].dispose())
// }
//
// document.addEventListener('turbo:frame-render', (e) => initBootstrapComponents(e.target));
// document.addEventListener('turbo:render', (e) => initBootstrapComponents(e.target));
//
// window.addEventListener('load', function() {
//   document.addEventListener('turbo:before-fetch-response', (e) => disableBootstrapComponents(e.target))
// })
//
