import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'

// These keys are ignored when filtering the results because they either don't
// help or are explicitly used by other controllers.
const IGNORE_KEYS = ['Escape', 'Enter', 'Tab', 'ArrowUp', 'ArrowDown', 'Control', 'Shift', 'Alt', 'Option', 'Meta']

export default class extends Controller {
  static targets = ['input', 'clear', 'value']
  static values = {
    submitDebounce: {
      type: Number,
      default: 200
    },
    emptyUrl: String
  }

  connect() {
    if (this.hasInputTarget) {
      this.showClear()

      // Move the cursor to the end of the input
      setTimeout(() => this.inputTarget.selectionStart = this.inputTarget.selectionEnd = 10000, 0)
    }

    this.submit = debounce(this.submit, this.submitDebounceValue)

    if (this.inputTarget.value.length == 0) {
      this.renderEmptyState()
    }
  }

  focus() {
    this.inputTarget.focus()
  }

  showClear() {
    if (this.hasClearTarget) {
      if (this.inputTarget.value != '') {
        this.clearTarget.style.display = 'block'
      }
      else {
        this.clearTarget.style.display = 'none'
      }
    }
  }

  clear() {
    if (this.hasClearTarget) {
      if (this.hasValueTarget) {
        this.valueTarget.value = ''
      }

      this.inputTarget.value = ''
      this.inputTarget.focus()

      this.showClear()
      this.submit()
    }
  }

  noop() {}

  filterKeydown(event) {
    if (IGNORE_KEYS.includes(event.key)) {
      return
    }

    this.submit()
  }

  submit() {
    if (this.inputTarget.value.length != 0) {
      this.element.requestSubmit()
    }
    else {
      this.renderEmptyState()
    }
  }

  renderEmptyState() {
    if (this.hasEmptyUrlValue) {
      document.querySelector(`#${this.element.dataset.turboFrame}`).src = this.emptyUrlValue
    }
  }
}
