import { Controller } from '@hotwired/stimulus'

// .col{ data: { controller: 'toggle'} }
//   %a{ data: { action: 'toggle#toggle' } }
//
//   .expanded-text.d-none{ data: { target: 'toggle.content', toggle_class: 'd-none' } }

// Nested Content
// Stimulus seems to reset the watchers if you have nested controllers.
// An example where this comes up is in our step form. In it we have something like
//
// .form{ data: { controller: 'toggle', action: 'keyup->toggle#remove change->toggle#remove'} }
//
//   .col{ data: controller: 'toggle' }
//     %a{ data: { action: 'toggle#toggle' } }
//
//     %input{ type: 'text' }
//
//     .expanded-text.d-none{ data: { toggle_target: 'content', toggle_class: 'd-none' } }
//
//   %button.disabled{ data: { toggle_target: 'content', toggle_class: 'disabled' } }
//
// The problem with the above is we have a toggle action watching for any changes on the step form
// B/C we re-initialize the toggle controller, changes on the input are not seen by the toggle form attatched to the form
//
// To Solve this we have to use nested methods
//
// .form{ data: { controller: 'toggle', action: 'keyup->toggle#remove change->toggle#remove'} }
//
//   .col
//     %a{ data: { action: 'toggle#nestedToggle' } }
//
//     %input{ type: 'text' }
//
//     .expanded-text.d-none{ data: { toggle_target: 'nested_content', toggle_class: 'd-none' } }
//
//   %button.disabled{ data: { toggle_target: 'content', toggle_class: 'disabled' } }

export default class extends Controller {

  static targets = ['content', 'nestedContent']

  static values = { targetSelf: Boolean }

  toggle(e) {
    e.preventDefault()
    this.contentTargets.forEach((t) => {
      t.dataset.toggleClass.split(' ').forEach((className) => {
        t.classList.toggle( className )
      })
    })
  }

  nested_toggle(e){
    e.preventDefault()
    let t = e.target.closest('.col').querySelector('[data-toggle-target="nestedContent"]')
    t.classList.toggle( t.dataset.toggleClass )
  }

  remove(e){
    e.preventDefault()

    const targets = [...this.contentTargets]

    if (this.targetSelfValue) {
      targets.push(this.element)
    }

    targets.forEach((t) => {
      t.dataset.toggleClass.split(' ').forEach((className) => {
        t.classList.remove( className )
      })
    })
  }

  add(e){
    e.preventDefault()

    const targets = [...this.contentTargets]

    if (this.targetSelfValue) {
      targets.push(this.element)
    }

    targets.forEach((t) => {
      t.dataset.toggleClass.split(' ').forEach((className) => {
        t.classList.add( className )
      })
    })
  }

}
