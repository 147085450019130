import { Controller } from '@hotwired/stimulus'
import camelCase from 'lodash/camelCase'

export default class ApplicationController extends Controller {
  connect() {
    super.connect()

    // Deprecated, use the outlets api instead
    this.element[camelCase(`${this.identifier}-controller`)] = this

    // Deprecated - could interfere with existing attributes
    this.element[camelCase(this.identifier)] = this

    this.dispatch('connected')
  }
}
