// TODO: I have slimselect in a pack so it shouldn't have to be imported from the controller here
// Figure out webpack and why this isn't working

import SlimSelect from 'slim-select'
import ApplicationController from '../lib/application_controller'

export default class extends ApplicationController {

  connect() {
    new SlimSelect({
      select: this.element,
      placeholder: this.element.dataset.placeholder,
      showSearch: (this.element.dataset.showsearch === 'false' ? false : true)
    })
  }
}
