import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['trigger']
  static values = {
    defer: { type: Number, default: 500 }
  }

  connect() {
    super.connect()

    document.addEventListener('scroll', () => this.toggleTriggers())

    // Assume not visible
    this.previousShow = false

    // Now update based on current scroll position
    this.toggleTriggers()
  }

  toggleTriggers() {
    const top = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0)

    const show = top > this.deferValue

    if (show == this.previousShow) {
      return
    }

    this.previousShow = show

    for (let trigger of this.triggerTargets) {
      if (show) {
        trigger.classList.add('show')
      }
      else {
        trigger.classList.remove('show')
      }
    }
  }

  go(event) {
    event.preventDefault()

    document.body.scrollIntoView({ behavior: 'smooth' })
  }
}
