import { Controller } from '@hotwired/stimulus'

// Ideally we'd use a resize observer and post messages from the child window
// to the parent window to handle resize events.
//
// This is a lazy cop-out.
export default class extends Controller {
  #timer

  get isParent() {
    return this.element.nodeName === 'IFRAME'
  }

  get isIframe() {
    return !this.isParent
  }

  connect() {
    if (this.isParent) {
      this.#timer = setInterval(() => this.resize(), 50)
    }
  }

  resize() {
    if (this.element.contentWindow) {
      this.element.style.height = this.element.contentWindow.document.body.scrollHeight + 'px'
    }
  }
}
