import { Controller } from '@hotwired/stimulus'
import { Workbox } from 'workbox-window'

//
// TODO: Doing it this way means that we capture the install prompt on
//       every page which may not be great, but it does mean we don't miss
//       the event ever. Not sure of which is ideal.
//
// let deferredPrompt
// window.addEventListener('beforeinstallprompt', (event) => {
//   event.preventDefault();
//   deferredPrompt = event
// })

export default class extends Controller {
  #deferredPrompt

  static targets = ['spinner', 'incompatible', 'install', 'alternateInstall', 'installing', 'installed', 'updateAvailable']

  static values = {
    serviceWorkerUrl: String,
    pwaUrl: String,
    installTimeout: {
      type: Number,
      default: 3000
    }
  }

  connect() {
    super.connect()

    if ('serviceWorker' in navigator) {
      this.start()
    }
    else {
      this.show('incompatible')
    }
  }

  start() {
    // Chrome/edge supports this, maybe firefox, not safari.
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault()

      this.#deferredPrompt = event

      this.show('install')
    })

    new Promise((resolve) => {
      setTimeout(() => { resolve() }, this.installTimeoutValue)
    }).then(() => {
      if (this.#deferredPrompt) {
        // Do nothing because we've received the deferred install prompt and
        // hopefully everything is good now?
      }
      else {
        // We haven't received the deferred install prompt so show the
        // alternate method.
        this.show('alternateInstall')
      }
    })
  }

  install() {
    this.show('installing')

    // Get the current page URL + all resources the page loaded.
    const urlsToCache = [
      this.pwaUrlValue,
      ...performance.getEntriesByType('resource').map(r => r.name),
    ]

    this.#deferredPrompt.prompt().then(({ outcome, platform }) => {
      console.log({ outcome, platform })
      if (outcome == 'dismissed') {
        this.show('alternateInstall')
      }
      else {
        const wb = new Workbox(this.serviceWorkerUrlValue)

        console.log(wb, this.serviceWorkerUrlValue, { scope: this.pwaUrlValue })

        wb.addEventListener('waiting', () => {
          console.log(
            'A new service worker has installed, but it can\'t activate' +
              'until all tabs running the current version have fully unloaded.'
          )
        })

        wb.addEventListener('message', event => {
          if (event.data.type === 'CACHE_UPDATED') {
            const {updatedURL} = event.data.payload

            console.log(`A newer version of ${updatedURL} is available!`)

            this.show('updateAvailable')
          }
        })

        wb.addEventListener('installed', event => {
          // if (!event.isUpdate) {
          console.log('installed', event)

          // }
        })

        wb.addEventListener('activated', event => {
          console.log('activated event!', event)



          console.log(urlsToCache)

          // Send that list of URLs to your router in the service worker.
          wb.messageSW({
            type: 'CACHE_URLS',
            payload: {urlsToCache},
          }).then(res => {
            console.log('cache response', res)

            this.show('installed')
          })

          //
          // TODO: This doesn't work on desktop since the window opens up and
          //   does a navigation before it can execute.
          //
          //   Instead the redirect is now handled inside frontend/index.js
          //   which needs to be refactored into something sane.
          //
          // // Quick pause to allow caches to download then redirect to the pwa
          // setTimeout(() => {
          //   window.location.href = this.pwaUrlValue
          // }, 1000)
        })

        console.log('registering')

        const registerResponse = wb.register()

        registerResponse.then(event => {
          console.warn('registered', event)

          // console.log("sending", urlsToCache)

          // wb.messageSW({
          //   type: 'CACHE_URLS',
          //   payload: {urlsToCache},
          // }).then(res => {
          //   console.log("cache response", res)
          // });
        }).then(() => {
          window.location.href = this.pwaUrlValue
        })

        console.log(registerResponse)
      }
    })

  }

  show() {
    this.spinnerTarget.classList.toggle('d-none', [...arguments].indexOf('spinner') == -1)
    this.alternateInstallTarget.classList.toggle('d-none', [...arguments].indexOf('alternateInstall') == -1)
    this.installingTarget.classList.toggle('d-none', [...arguments].indexOf('installing') == -1)
    this.installTarget.classList.toggle('d-none', [...arguments].indexOf('install') == -1)
    this.installedTarget.classList.toggle('d-none', [...arguments].indexOf('installed') == -1)
    this.updateAvailableTarget.classList.toggle('d-none', [...arguments].indexOf('updateAvailable') == -1)
    this.incompatibleTarget.classList.toggle('d-none', [...arguments].indexOf('incompatible') == -1)
  }
}
