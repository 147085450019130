import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [ 'notification' ]

  connect() {
    setTimeout(() => {
      this.close()
    }, 2000)
  }

  close(){
    this.notificationTarget.classList.add('hide')
    this.notificationTarget.classList.remove('show')
  }


}
