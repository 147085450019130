/* eslint no-console:0 */

import $ from 'jquery'
window.jQuery = window.$ = $ // Make jquery global

import '../common'

import { application } from '../controllers/application'

// Load all controllers all the time?
import controllers from '../controllers/{common,frontend}/*_controller.js'
controllers.forEach((controller) => {
  application.register(
    controller.name.replace(/\.\.--controllers--(common|admin|community|frontend)--/, ''),
    controller.module.default
  )
})

import consumer from '../channels/consumer'
application.consumer = consumer

// Need these from community when editing user profile
import MultiSelectController from '../controllers/community/multi_select_controller'
application.register('multi-select', MultiSelectController)

import AvatarController from '../controllers/community/avatar_controller'
application.register('avatar', AvatarController)

// Experimental view transitions
//
// TODO: Disable this in the PWA
//
const viewTransitionHandler = (event) => {
  if (document.startViewTransition) {
    const originalRender = event.detail.render
    event.detail.render = (currentElement, newElement) => {
      document.startViewTransition(()=> originalRender(currentElement, newElement))
    }
  }
}

addEventListener('turbo:before-render', viewTransitionHandler)
addEventListener('turbo:before-frame-render', viewTransitionHandler)

// This event fires whenever display-mode changes - we can use this to determine
// if the user has clicked "Open in Chrome" from within the PWA or "Open with"
// from within Chrome.
//
// I don't know how well this is supported in firefox/safari, but I presume Edge
// is just fine.
//
// TODO: Find a smarter place to put all of this logic.
//
const mediaQueryList = window.matchMedia('(display-mode: standalone)')
mediaQueryList.onchange = () => {
  const pwaUrl = window.location.href.indexOf('/pwa/') > -1
  const pwaApp = navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches

  if (pwaUrl && pwaApp) {
    // console.log('Correctly in PWA')
  }
  else if (pwaUrl && !pwaApp) {
    console.error('PWA URL in a regular browser\n\nRewrite request to regular browser URL')

    const regex = new RegExp(/^\/pwa\/clients\/(?<clientId>\d+)(?<path>\/.*)?/)
    const matches = window.location.pathname.match(regex)

    if (matches.groups.path === '/home') {
      matches.groups.path = ''
    }

    const nonPwaPath = `/clients/${matches.groups.clientId}${matches.groups.path}`

    console.log(`now is when I would rewrite ${window.location.pathname} to ${nonPwaPath}`)

    // window.location.pathname = nonPwaPath
  }
  else if (!pwaUrl && pwaApp) {
    console.error('Regular URL in a PWA\n\nRewrite request to PWA URL')

    if (window.location.href.indexOf('/install') > -1) {
      // window.location.href = this.pwaUrlValue
      console.log('Would be redirecting now')
    }
    else {
      // const regex = new RegExp(/^\/((?<locale>[^/]+)\/)?clients\/(?<clientHandle>(?<clientId>\d+)(-[^/]+))?(?<path>\/.*)?/)
      // const matches = window.location.pathname.match(regex)

      // if (matches.groups.path === undefined || matches.groups.path == "/" || matches.groups.path == "/app") {
      //   matches.groups.path = "/home"
      // }

      // const pwaPath = `/pwa/clients/${matches.groups.clientId}${matches.groups.path}`

      // window.location.pathname = pwaPath

      console.log('TODO: Handle deep urls')
    }
  }
  else {
    // console.log('Correctly in browser')
  }
}

// Trigger this once on page startup.
mediaQueryList.onchange()
