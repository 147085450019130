import '@popperjs/core'
import 'bootstrap-5'

require('@hotwired/turbo-rails')

// TODO: Once we remove the ujs links we can remove this.
import Rails from '@rails/ujs'
window.Rails = Rails
Rails.start()

import './bootstrap/initialise_tooltips'
import './bootstrap/unload_components_on_turbo_page_cache'

// Add a Turbo header to any request
document.addEventListener('turbo:before-fetch-request', (event) => {
  event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href
})
