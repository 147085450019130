import ApplicationController from '../lib/application_controller'
import Cropper from 'cropperjs'

export default class extends ApplicationController {
  connect() {
    super.connect()
    this.setAvatarUploader()
  }

  setAvatarUploader() {
    const image = document.getElementById('image')
    const avatarInput = document.getElementById('avatar-input')
    let cancelButton = document.getElementById('cancel-upload')
    let useButton = document.getElementById('upload-avatar')
    let cropper, reader, file, canvas


    avatarInput.addEventListener('change', (e) => {
      let files = e.target.files

      let done = function(url) {
        image.src = url
        document.querySelector('.img-container').classList.remove('d-none')
        document.getElementById('current-avatar').classList.add('d-none')
        document.querySelector('.preview').classList.remove('d-none')
        document.querySelector('.image-input').classList.add('d-none')
        document.querySelector('.crop-buttons').classList.remove('d-none')
      }

      if (files && files.length > 0) {
        file = files[0]

        if (URL) {
          done(URL.createObjectURL(file))
        } else if (FileReader) {
          reader = new FileReader()
          reader.onload = function() {
            done(reader.result)
          }

          reader.readAsDataURL(file)
        }
      }

      cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 3,
        preview: '.preview',
      })
    })

    cancelButton.addEventListener('click', (event) => {
      event.preventDefault()
      cropper.destroy()
      cropper = null
      document.querySelector('.img-container').classList.add('d-none')
      document.getElementById('current-avatar').classList.remove('d-none')
      document.querySelector('.preview').classList.add('d-none')
      document.querySelector('.image-input').classList.remove('d-none')
      document.querySelector('.crop-buttons').classList.add('d-none')
      document.getElementById('avatar-input').value = null
    })

    useButton.addEventListener('click', (event) => {
      event.preventDefault()
      canvas = cropper.getCroppedCanvas({
        width: 250,
        height: 250,
      })
      let roundedCanvas = this.getRoundedCanvas(canvas)

      roundedCanvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = function() {
          let base64data = reader.result
          // Update the input wit hthe new b64 data to be stored:
          document.getElementById('user_avatar').value = base64data
          // Display the new image on the form:
          document.getElementById('current-avatar').src = base64data
          // Hide the cropper:
          cropper.destroy()
          cropper = null
          document.querySelector('.img-container').classList.add('d-none')
          document.getElementById('current-avatar').classList.remove('d-none')
          document.querySelector('.preview').classList.add('d-none')
          document.querySelector('.image-input').classList.remove('d-none')
          document.querySelector('.crop-buttons').classList.add('d-none')
        }
      })
    })

  }



  getRoundedCanvas(sourceCanvas) {
    var canvas = document.createElement('canvas')
    var context = canvas.getContext('2d')
    var width = sourceCanvas.width
    var height = sourceCanvas.height

    canvas.width = width
    canvas.height = height
    context.imageSmoothingEnabled = true
    context.drawImage(sourceCanvas, 0, 0, width, height)
    context.globalCompositeOperation = 'destination-in'
    context.beginPath()
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true)
    context.fill()
    return canvas
  }
}
