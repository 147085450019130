import { Controller } from '@hotwired/stimulus'
import ahoy from 'ahoy.js'

ahoy.configure({
  cookies: false,
  visitsUrl: '/squawk/visits',
  eventsUrl: '/squawk/events',
})

export default class extends Controller {
  static values = {
    event: String,
    properties: Object,
  }

  connect() {
    if (this.hasEventValue) {
      ahoy.track(this.eventValue, this.propertiesValue)
    }
  }
}
