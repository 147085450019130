import ApplicationController from '../lib/application_controller'

export default class extends ApplicationController {

  static targets = ['input', 'results']

  connect() {
    this.element.addEventListener('keydown', (e) => {
      if(e.key === "Escape") {
        this.clearResults()
      }
    })
    super.connect()
  }

  getResults() {
    // show results box when value present
    if (this.inputTarget.value != '') {
      this.resultsTarget.classList.add('show')
    } else {
      this.resultsTarget.classList.remove('show')
    }
  }

  clearResults() {
    this.inputTarget.value = ''
    this.resultsTarget.classList.remove('show')
  }
}
