import { Controller } from '@hotwired/stimulus'

let debounce = require('lodash/debounce')

export default class extends Controller {
  static values = { debounce: Number }

  connect() {
    this.submit = debounce(this.submit, this.debounceValue)
  }

  submit(event) {
    // Don't fire if this is a sortablejs event
    //
    // TODO: Find a way to be more explicit about this
    //
    if (event.hasOwnProperty('oldIndex')) {
      return
    }

    this.element.requestSubmit()
  }
}
