import ApplicationController from '../lib/application_controller'

var bootstrap = require('bootstrap-5/dist/js/bootstrap.esm') // TODO: wtf

var myDefaultAllowList = bootstrap.Tooltip.Default.allowList

// To allow table elements
myDefaultAllowList['turbo-frame'] = ['src']

export default class extends ApplicationController {

  static targets = []

  static values = { clientId: String }

  connect() {
    this.editor = this.element.editor
    this.enableTooltipAndPopover()

    document.addEventListener('content-search:contentSet', (event) => {
      this.insert(event.detail)
    })
  }

  // preview(){

  // }

  insert(element) {
    let attachment = new Trix.Attachment({
      content: `
      <a href="${element.dataset.url}" class="d-flex w-50 text-decoration-none align-items-center" target="_blank">
        <div class="resourcelink__icon resourcelink__icon--${element.dataset.resourceType} align-self-stretch d-flex align-items-center p-3 border-start border-top border-bottom"></div>
        <div class="resourcelink__name flex-grow-1 p-3 border-top border-bottom align-self-stretch w-100">
          <p class="text-truncate resourcelink__title fw-bold my-0">${element.dataset.resourceName}</p>
          <p class="text-muted mb-0 text-truncate">${element.dataset.clientName}</p>
        </div>
        <div class="resourcelink__link align-self-stretch d-flex align-items-center border-end border-top border-bottom p-3"></div>
      </a>`,
      contentType: 'firstline-link'
    })

    // Insert attachment
    this.editor.insertAttachment(attachment)

    // Dispose of the popover and re-initiate it so inserting the turbo-frame still happens and causes a fetch
    this.popover.dispose()
    this.initializePopover()
  }

  enableTooltipAndPopover() {
    // Add button to toolbar
    var buttonHTML = `<button type="button"
                            id='myPopover'
                            class="trix-button trix-button--icon trix-button--icon-firstline"
                            data-trix-attribute="firstline"
                            data-bs-toggle="popover"
                            data-bs-html="true"
                            data-bs-placement="right">
                              Firstline
                    </button>`

    var buttonGroup = this.element.toolbarElement.querySelector('.trix-button-group--text-tools')
    buttonGroup.insertAdjacentHTML('beforeend', buttonHTML)

    this.initializePopover()
  }

  initializePopover(){
    this.popover = new bootstrap.Popover(document.querySelector('#myPopover'), {
      container: 'body',
      content: `<turbo-frame id='firstline-button' src='/firstline_button?client_id=${this.clientIdValue}'><div class='popover-div'></div></turbo-frame>`
    })
  }

}
