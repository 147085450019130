import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [ 'password', 'confirmPassword' ]

  validate(event){
    if (this.passwordTarget.value === this.confirmPasswordTarget.value) {
      return true
    } else {
      event.preventDefault()
      $('.invalid-feedback-password').removeClass('d-none')
      return false
    }
  }


}
