import { Controller } from '@hotwired/stimulus'

// This controller is to open dropdowns based on present page anchors
// It will look at the url, grab the page anchors, find the dropdown based on a class name
// It will then mark the closest child aria-toggle as true

export default class extends Controller {

  connect(){
    console.warn('TODO: Replace this')

    let anchor = window.location.hash
    let cell = document.getElementsByClassName(anchor.replace('#', ''))[0]

    if (cell) {
      cell.querySelector('a').setAttribute('aria-expanded', 'true')
      cell.querySelector('.detail-container').classList.add('show')
    }
  }

}
