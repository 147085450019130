import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  #enabled = true

  static targets = ['template']
  static values = {
    targetSelector: {
      type: String,
      default: '#remote'
    }
  }

  get target() {
    return document.querySelector(this.targetSelectorValue)
  }

  get template() {
    return this.templateTarget.innerHTML
  }

  enable() {
    this.#enabled = true
  }

  disable() {
    this.#enabled = false
  }

  show() {
    if (!this.#enabled) return

    if (this.target) {
      this.target.innerHTML = ''
      this.target.innerHTML = this.template
    }
  }

  // No hide, dispose, or other methods for this controller as we're expecting
  // the modal to get completely monstered by navigating to a new page.
}
