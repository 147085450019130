import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['element']

  connect() {
    super.connect()

    if (this.hasElementTarget) {
      this.elementTarget.focus()
    }
    else {
      this.element.focus()
    }
  }
}
