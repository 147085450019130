import { Controller } from '@hotwired/stimulus'

// Currently this class clones the element it's created from to create the
// digit input elements. Classes can be added and removed with the classList
// value.
export default class extends Controller {

  static values = { count: Number, autoSubmit: Boolean, classList: Object }

  get count() {
    if (this.hasCountValue) {
      return this.countValue
    }
    else {
      return 4
    }
  }

  get mobile() {
    return /Mobi|Android/i.test(navigator.userAgent)
  }

  get complete() {
    return this.element.value.length == this.count
  }

  connect() {
    let blankInput = this.element.cloneNode()
    blankInput.id = null
    blankInput.dataset.controller = blankInput.dataset.controller.replace(this.identifier, '')

    // Hide this node
    this.element.type = 'hidden'

    // Create new nodes
    this.inputs = []
    let lastInput = this.element
    for (var i = 0; i < this.count; i++) {
      let newInput = blankInput.cloneNode()
      newInput.name = ''
      newInput.id = ''
      newInput.maxLength = 1

      if (this.mobile) {
        newInput.type = 'number'
      }

      if (this.classListValue.add) {
        newInput.classList.add(...this.classListValue.add)
      }
      if (this.classListValue.remove) {
        newInput.classList.remove(...this.classListValue.remove)
      }

      newInput.addEventListener('keydown', (e) => this.handleKeyDown(e))
      newInput.addEventListener('keyup', (e) => this.handleKeyUp(e))
      newInput.addEventListener('input', (e) => this.handleInput(e))
      newInput.addEventListener('paste', (e) => this.handlePaste(e))

      this.inputs.push(newInput)
      lastInput.after(newInput)
      lastInput = newInput
    }

    this.inputs[0].focus()
  }

  handleKeyDown(event) {
    if (!this._isValidOtpField(event.key)) {
      event.preventDefault()
    }
  }

  handleKeyUp(event) {
    let previousInput = this.inputs[this.inputs.indexOf(event.currentTarget) - 1]
    let nextInput = this.inputs[this.inputs.indexOf(event.currentTarget) + 1]

    if (event.key === 'Backspace' || event.key === 'ArrowLeft') {
      if (previousInput) {
        previousInput.focus()
      }
      this.update()
    }
    else if (event.key === 'ArrowRight') {
      if (nextInput) {
        if (nextInput.value.length == 1) {
          nextInput.setSelectionRange(1, 1)
        }

        nextInput.focus()
      }
    }
  }

  handleInput(event) {
    const digitValue = event.data
    if (digitValue == null) { return }

    let nextInput = this.inputs[this.inputs.indexOf(event.currentTarget) + 1]

    if(('0' <= digitValue && digitValue <= '9') || ('a' <= digitValue && digitValue <= 'z')) {
      if (nextInput) {
        if (nextInput.value.length == 1) {
          nextInput.setSelectionRange(1, 1)
        }

        nextInput.focus()
      }
    }

    this.update()

    if (this.autoSubmitValue && this.complete) {
      this.element.closest('form').requestSubmit()
    }
  }

  handlePaste(event) {
    let paste = (event.clipboardData || window.clipboardData).getData('text')
    let split = paste.split('')
    let value

    for(var i = this.inputs.indexOf(event.currentTarget); i < this.inputs.length; i++) {
      do { value = split.shift() } while (split.length > 0 && !this._isValidOtpField(value))
      this.inputs[i].value = value
      this.inputs[i].focus()
    }

    this.update()

    if (this.autoSubmitValue && this.complete) {
      this.element.closest('form').requestSubmit()
    }
  }

  update() {
    this.element.value = this.inputs.map((i) => i.value).join('')
  }

  _isValidOtpField(key) {
    return (key === 'Backspace') ||
            (key === 'ArrowLeft') ||
            (key === 'ArrowRight') ||
            (key === 'Tab') ||
            ('0' <= key && key <= '9') ||
            ('a' <= key && key <= 'z')
  }
}
