import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { param: String, chromeless: Boolean, cache: Boolean }
  static targets = ['append', 'clientForm']

  connect() {
    super.connect()

    if (this.hasParamValue && !!this.paramValue) {
      this.appendTargets.forEach((target) => {
        if (target.nodeName == 'A') {
          target.href = this.appendToURL(target.href)
        }
        else if (target.nodeName == 'FORM') {
          target.action = this.appendToURL(target.action)
        }
        else {
          console.warn('Don\'t know how to append navigator to', target)
        }
      })

      // This is an ugly hack to set the navigator on the state params
      // attribute consumed by the client forms previewer controller.
      this.clientFormTargets.forEach((target) => {
        let original = target.dataset.clientFormStateParamsValue
        let param = `navigator=${this.paramValue}`
        let modified

        if (this.chromelessValue) {
          param = `${param}&chromeless=true`
        }

        // Only include cache value when it is disabled
        if (!this.cacheValue) {
          param = `${param}&cache=false`
        }

        if (original) {
          modified = `${original}&${param}`
        }
        else {
          modified = param
        }

        target.dataset.clientFormStateParamsValue = modified
      })
    }
  }

  appendToURL(urlString) {
    let url = new URL(urlString)
    let searchParams = url.searchParams

    searchParams.set('navigator', this.paramValue)

    // Only include chromeless when it is true
    if (this.chromelessValue) {
      searchParams.set('chromeless', this.chromelessValue)
    }
    else {
      searchParams.delete('chromeless')
    }

    // Only include cache when it is false
    if (this.cacheValue) {
      searchParams.delete('cache')
    }
    else {
      searchParams.set('cache', this.cacheValue)
    }

    url.search = searchParams.toString()

    return url.toString()
  }
}
