import ApplicationController from '../lib/application_controller'
import { Popover } from 'bootstrap-5'

function elementFromString(str) {
  var template = document.createElement('template')
  template.innerHTML = str.trim()
  return template.content.firstChild
}

export default class extends ApplicationController {

  static values = { symbolList: Object }

  get buttonHTML() {
    return `
      <button type="button"
        class="trix-button trix-button--icon trix-button--icon-special-character"
        data-trix-attribute="special-character"
        data-bs-toggle="popover"
        data-bs-html="true"
        data-bs-placement="right">
          Special Character
      </button>`
  }

  get buttonElement() {
    if (!this._buttonElement) {
      this._buttonElement = elementFromString(this.buttonHTML)
    }

    return this._buttonElement
  }

  get selectorElement() {
    if (!this._selectorElement) {
      let container = elementFromString('<div></div>')

      this.symbolList.forEach((symbol) => {
        let element

        if (symbol == null) {
          element = elementFromString('<br>')
        }
        else {
          element = elementFromString(`<a href="#" class="font-monospace rounded trix--special-charactor-icon d-inline-block p-2 link-dark text-decoration-none fs-5">${symbol}</a>`)
          element.addEventListener('click', (event) => {
            event.preventDefault()
            this.insert(symbol)
            this.popover.hide()
          })
        }

        container.appendChild(element)
      })

      this._selectorElement = container
    }

    return this._selectorElement
  }

  get symbolList() {
    if (this.hasSymbolListValue) {
      return this.symbolListValue
    }
    else {
      return ['°', '≤', '≥', '≃', null, '©', '®', '™']
    }
  }

  connect() {
    this.editor = this.element.editor
    this.addEditorButton()
  }

  insert(symbol) {
    this.editor.recordUndoEntry('Insert symbol')
    this.editor.insertString(symbol)
  }

  addEditorButton() {
    var buttonGroup = this.element.toolbarElement.querySelector('.trix-button-group--text-tools')
    buttonGroup.insertAdjacentElement('beforeend', this.buttonElement)
    this.popover = new Popover(this.buttonElement, {
      container: 'body',
      content: this.selectorElement
    })
  }
}
