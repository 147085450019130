// Globally initialise tooltips.
import { Tooltip } from 'bootstrap-5'

function initialiseTooltips() {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
}

window.addEventListener('DOMContentLoaded', initialiseTooltips)
window.addEventListener('turbo:render', initialiseTooltips)
