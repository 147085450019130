import { Controller } from '@hotwired/stimulus'

// Generates a data attribute with the application specific link data removed
export default class extends Controller {
  connect() {
    let url = new URL(this.element.href)

    url.searchParams.delete('cache')
    url.searchParams.delete('chromeless')
    url.searchParams.delete('release_id')
    url.searchParams.delete('show_draft')
    url.searchParams.delete('version_date')
    url.searchParams.delete('version_id')
    url.searchParams.delete('navigator')
    url.searchParams.delete('screens')

    this.element.dataset.resourceUrl = url.toString()
  }
}
