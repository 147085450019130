// Listen for bootstrap modals and tooltips and unload them before turbo caching
import { Modal, Tooltip } from 'bootstrap-5'

let bootstrapComponents = []

window.addEventListener('show.bs.modal', (event) => {
  bootstrapComponents.push(Modal.getInstance(event.target))
})

window.addEventListener('show.bs.tooltip', (event) => {
  bootstrapComponents.push(Tooltip.getInstance(event.target))
})

window.addEventListener('turbo:before-cache', () => {
  let instance
  while (instance = bootstrapComponents.pop()) { /* eslint no-cond-assign:0 */
    try {
      if (instance) instance.dispose()
    } catch(err) {
      // console.log(err)
    }
  }
})
