import ApplicationController from '../lib/application_controller'

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

function scrollStop(callback, refresh = 50) {
  let isScrolling
  window.addEventListener('scroll', function () {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(callback, refresh)
  }, false)
}

export default class extends ApplicationController {

  static targets = ['iframe', 'enabled']

  static values = {
    emit: Boolean
  }

  get windowId() {
    const key = `${this.identifier}-id`

    if (!window[key]) {
      window[key] = getRandomInt(1, 999999)
    }

    return window[key]
  }

  get parentWindow() {
    return window.parent
  }

  get clickPropagationEnabled() {
    const key = `${this.identifier}-click-propagation-disabled`

    if (window[key]) {
      return false
    }

    return true
  }

  get enabled() {
    if (this.hasEnabledTarget) {
      return this.enabledTarget.checked
    }

    return true
  }

  disableClickPropagation(timeout = 100) {
    const key = `${this.identifier}-click-propagation-disabled`

    window[key] = true

    setTimeout(function() {
      window[key] = false
    }, timeout)
  }

  connect() {
    super.connect()

    this.eventListeners = []

    // Listen for events generated on the current page
    if (this.emitValue && this.parentWindow) {
      this.registerEventListener('click', e => this.handleClick(e))
      this.registerEventListener('scroll', e => this.handleScroll(e))
    }

    // Listen for propagated messages from child iframes
    this.registerEventListener('message', e => this.handleMessage(e))
  }

  disconnect() {
    this.eventListeners.forEach((e) => {
      window.removeEventListener(e.event, e.handler)
    })

    super.disconnect()
  }

  registerEventListener(event, handler) {
    window.addEventListener(event, handler)
    this.eventListeners.push({event: event, handler: handler})
  }

  handleClick(event) {
    if (!this.clickPropagationEnabled) {
      return
    }

    let selector
    let target = event.target.closest('a,button')

    if (target.id) {
      selector = `#${target.id}`
    }
    else if (target.dataset.bsTarget) {
      selector = `${target.nodeName}[data-bs-toggle="${target.getAttribute('data-bs-toggle')}"][data-bs-target="${target.getAttribute('data-bs-target')}"]`
    }
    else if (target.nodeName == 'A') {
      if (target.getAttribute('data-resource-url')) {
        selector = `${target.nodeName}[data-resource-url="${target.getAttribute('data-resource-url')}"]`
      }
      else {
        selector = `${target.nodeName}[href="${target.getAttribute('href')}"]`
      }
    }
    else {
      console.warn('Can\'t generate selector for element', target)
      return
    }

    const eventData = {
      controller: this.identifier,
      sourceWindowId: this.windowId,
      action: 'click',
      selector: selector
    }

    this.parentWindow.postMessage(JSON.stringify(eventData))
  }

  handleScroll() {
    if (this.scrollingFromMessage) {
      return
    }

    const eventData = {
      controller: this.identifier,
      sourceWindowId: this.windowId,
      action: 'scroll',
      left: window.scrollX,
      top: window.scrollY
    }

    this.parentWindow.postMessage(JSON.stringify(eventData))
  }

  handleMessage(event) {
    if (!this.enabled) {
      return
    }

    let parsed

    try {
      parsed = JSON.parse(event.data)
    } catch(e) {
      console.log(e)
      return
    }

    if (parsed.controller !== this.identifier) {
      return
    }

    if (this.hasIframeTarget) {
      this.propagateEvent(parsed)
    }
    else if (parsed.sourceWindowId !== this.windowId) {
      this.repeatInteraction(parsed)
    }
  }

  propagateEvent(eventData) {
    this.iframeTargets.forEach(iframe => {
      iframe.contentWindow.postMessage(JSON.stringify(eventData))
    })
  }

  repeatInteraction(eventData) {
    let element

    switch (eventData.action) {
    case 'click':
      element = document.querySelector(eventData.selector)
      if (element) {
        this.disableClickPropagation()
        element.click()
      }
      break

    case 'scroll':
      this.scrollingFromMessage = true
      window.scrollTo(eventData.left, eventData.top)
      scrollStop(() => this.scrollingFromMessage = false)
      break

    default:
      console.warn('Don\'t know what to do with', eventData.action)
    }
  }
}
