import ApplicationController from '../lib/application_controller'

export default class extends ApplicationController {

  static targets = ['bar', 'input']

  get parent() {
    return this.element.parentElement
  }

  open() {
    this.toggleSurroundingContent()

    // Show input target
    if (this.hasBarTarget) {

      this.barTarget.classList.remove('d-none')
    }

    // Focus
    if (this.hasInputTarget) {
      this.inputTarget.focus()
    }
  }

  close() {
    this.toggleSurroundingContent()

    if (this.hasBarTarget) {
      this.barTarget.classList.add('d-none')
    }
  }

  toggleSurroundingContent() {
    // Set width to 100% when in open state
    this.element.classList.toggle('w-100')

    // Toggle top level items (content around search bar)
    this.parent.children.forEach(element => {

      // Toggle items in search-wrapper, open button and searchbar + close
      if (element.classList.contains('search-wrapper')) {
        element.children.forEach(child => {
          child.classList.toggle('d-none')
        })
      } else {

        element.classList.toggle('d-none')
      }
    })
  }
}
