/*
  Usage:

  <div class="modal fade"
    data-controller="modal"
    data-modal-open-value="true">
    <div class="modal-dialog modal-lg">
      ...
    </div>
  </div>
*/

import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap-5'

export default class extends Controller {

  static values = {
    open: Boolean,
    removeOnHide: Boolean
  }

  static targets = ['autofocus']

  get modal() {
    return Modal.getOrCreateInstance(this.element)
  }

  connect() {
    if (this.openValue) {
      this.show()
    }

    if (this.removeOnHideValue) {
      this.element.addEventListener('hidden.bs.modal', () => { this.element.remove() })
    }

    this.element.addEventListener('shown.bs.modal', () => this.autofocus())

    this.element.addEventListener('shown.bs.modal', () => this.startListener())
    this.element.addEventListener('hidden.bs.modal', () => this.stopListener())

    window.addEventListener('turbo:before-cache', () => this.remove())
  }

  disconnect() {
    this.stopListener()
  }

  toggle() {
    this.modal.toggle()
  }

  show() {
    this.modal.show()
  }

  hide() {
    this.modal.hide()
  }

  remove() {
    this.element.remove()
  }

  autofocus() {
    if (this.hasAutofocusTarget) {
      if (document.activeElement) document.activeElement.blur()
      this.autofocusTarget.focus()
    }
  }

  startListener() {
    this.hideListener = window.addEventListener('turbo:before-stream-render', ((event) => {
      const fallbackToDefaultActions = event.detail.render

      event.detail.render = (streamElement) => {
        if (streamElement.action == 'hide_modal') {
          this.hide()
        } else {
          fallbackToDefaultActions(streamElement)
        }
      }
    }))
  }

  stopListener() {
    window.removeEventListener('turbo:before-stream-render', this.hideListener)
  }
}
