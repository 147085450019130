import { Controller } from '@hotwired/stimulus'

const ENTER_KEY = 'Enter'
const ARROW_UP_KEY = 'ArrowUp'
const ARROW_DOWN_KEY = 'ArrowDown'

function getScrollParent(node) {
  if (node == null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentNode)
  }
}

function isElementContained(innerElement, outerElement) {
  const innerRect = innerElement.getBoundingClientRect()
  const outerRect = outerElement.getBoundingClientRect()

  return (
    innerRect.top >= outerRect.top &&
    innerRect.left >= outerRect.left &&
    innerRect.bottom <= outerRect.bottom &&
    innerRect.right <= outerRect.right
  )
}

export default class extends Controller {
  static values = {
    resultsSelector: String,
    resultsContainerSelector: String,
    focusClass: {
      type: String,
      default: 'cell-focus'
    }
  }

  get items() {
    return Array.from(document.querySelectorAll(this.resultsSelectorValue)).filter(i => !i.classList.contains('d-none'))
  }

  filterKeydown(event) {
    const isSelectEvent = [ENTER_KEY].includes(event.key)
    const isUpOrDownEvent = [ARROW_UP_KEY, ARROW_DOWN_KEY].includes(event.key)

    if (!isUpOrDownEvent && !isSelectEvent) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    if (isUpOrDownEvent) {
      this.focusItem(event)
    }
    else if (isSelectEvent) {
      this.selectItem(event)
    }
  }

  focusItem(event) {
    const items = this.items
    const index = this.getNextItemIndex(event, items)

    this.focusItemAtIndex(items, index)
  }

  focusItemAtIndex(items, index) {
    if (!items[index]) {
      return
    }

    const focusClass = this.focusClassValue

    // Remove the focus class from all focussed items
    items.filter(i => i.classList.contains(focusClass)).forEach(i => i.classList.remove(focusClass))

    // Add the focus class to the newly selected item
    items[index].classList.add(focusClass)

    // If the item is not visible, scroll to make it visible
    const scrollParent = getScrollParent(items[index])
    if (scrollParent && !isElementContained(items[index], scrollParent)) {
      items[index].scrollIntoView()
    }
  }

  getNextItemIndex(event, items) {
    const focusClass = this.focusClassValue
    const isUpEvent = event.key == ARROW_UP_KEY
    const isDownEvent = event.key == ARROW_DOWN_KEY

    let index = items.findIndex(i => i.classList.contains(focusClass))
    if (isUpEvent) {
      if (index == -1) {
        index = items.length - 1
      }
      else {
        index = index - 1
      }
    }
    else if (isDownEvent) {
      if (index == -1) {
        index = 0
      }
      else {
        index = index + 1
      }
    }

    // If the index is out of bounds, loop around
    if (index < 0) {
      index = items.length - 1
    }
    else if (index >= items.length) {
      index = 0
    }

    return index
  }

  selectItem() {
    const focusClass = this.focusClassValue
    const items = Array.from(document.querySelectorAll(this.resultsSelectorValue))
    const selectedItem = items.find(i => i.classList.contains(focusClass))

    if (selectedItem) {
      selectedItem.querySelector('a').click()
    }
  }

  expand(event) {
    Array.from(document.querySelectorAll(event.params.removeSelector)).forEach(n => n.remove())

    const toggleClasses = event.params.class.split(' ')
    const elements = Array.from(document.querySelectorAll(event.params.selector))
    elements.forEach(n => toggleClasses.forEach(c => n.classList.toggle(c)))

    if (event.pointerId == -1) {
      const items = this.items
      const index = items.indexOf(elements[0])
      this.focusItemAtIndex(items, index)
    }
  }
}
