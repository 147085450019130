import ApplicationController from '../lib/application_controller'

export default class extends ApplicationController {

  static targets = ['editor', 'icon']

  get editorText(){
    return this.editor.getDocument().toString()
  }

  connect(){
    this.editor = this.editorTarget.editor

    // Set up watcher
    document.addEventListener('trix-change', this.trixChange.bind(this))

    // Call on initialize to catch first load
    this.trixChange()
  }

  clear(){
    this.editor.loadHTML('')
  }

  trixChange(){
    const text = this.editorText

    if (text === '\n' || text === '') {
      this.iconTarget.classList.add('d-none')
    } else {
      this.iconTarget.classList.remove('d-none')
    }
  }

}
