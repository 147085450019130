import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [ "source" ]

  copy(event) {
    event.preventDefault()

    if (this.hasSourceTarget) {
      if (this.sourceTarget.type == "hidden") {
        this.copyOffscreen()
      }
      else {
        this.sourceTarget.select()
        document.execCommand("copy")
      }
    }
  }

  // Creates a temporary textarea element off-canvas, inserts the source value
  // into the new field, then selects and copies it to the clipboard. The
  // textarea is then removed from the DOM:
  copyOffscreen() {
    var textarea = document.createElement('textarea')
    textarea.value = this.sourceTarget.value
    // Set non-editable to avoid focus and move outside of view
    textarea.setAttribute('readonly', '')
    textarea.style = {position: 'absolute', left: '-9999px'}
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }
}
