import { Application, defaultSchema } from '@hotwired/stimulus'

const customSchema = {
  ...defaultSchema,
  keyMappings: { ...defaultSchema.keyMappings, period: '.', comma: ',', forwardSlash: '/' },
}

const application = Application.start(document.documentElement, customSchema)

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }
