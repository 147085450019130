import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { message: String }

  ask(event) {
    if (!(window.confirm(this.messageValue))) {
      event.preventDefault()
      event.target.blur()
    }
  }
}
