import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal']

  showLoadingSpinnerAndDisableFormInput(event) {
    const selectionIdentifier = event.currentTarget.dataset.identifier
    console.log(selectionIdentifier)

    let notSpinner = document.getElementById(`not-spinner-${selectionIdentifier}`)
    notSpinner.style.setProperty('display', 'none', 'important')
    
    let spinner = document.getElementById(`spinner-${selectionIdentifier}`)
    spinner.style.setProperty('display', 'block', 'important')
  }
}
