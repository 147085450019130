import { Controller } from '@hotwired/stimulus'
import getFormData from 'get-form-data'
import Calculator from 'calculators-js/src/v2'
import validate from 'validate.js'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'clientId', 'settings', 'input', 'inputs', 'operations', 'notes', 'actions', 'results', 'form', 'resultsContainer' ]

  static values = {
    stateParams: String,
    preview: Boolean,
  }

  connect() {
    super.connect()

    this.setConstraints()
  }

  submitForm(event) {
    event.preventDefault()

    const data = this.getDataFromTheForm()

    this.validateInputs()

    let hasErrors = Array.from(document.getElementsByClassName('has-error')).map((item) => {
      return { item }
    })

    if (!hasErrors.length) {
      if (data.form_type === 'calculator') {
        const formEntryValues = new Map()
        let calculatorElements = this.constructCalculator()
        let calculator = new Calculator(calculatorElements)
        calculator.inputData = data



        // Collect all entered data and match it with a label
        // so we can create a form entry record;
        // We also have to clean up the calculator.inputData by
        // asigning a default value if nothing was entered by the user
        // and that value is defined;
        for (const [key, value] of Object.entries(data)) {
          if (key.includes('$')) {
            const obj = JSON.parse(data.inputs).find(el => el.identifier === key)

            if (value === '' && Object.prototype.hasOwnProperty.call(obj, 'no_input_value')) {
              formEntryValues.set(key, {result: `${obj.label}: ${parseFloat(obj.no_input_value)}`})
            } else {
              formEntryValues.set(key, {result: `${obj.label}: ${value}`})
            }
          }
        }

        let obj = this.runCalculator(calculator)

        obj.results.map((res) => {
          formEntryValues.set(res.id, {result: `${res.prefix} ${res.value} ${res.suffix}`})
        })

        let myData = {
          content_id: data.content_id,
          content_type: data.content_type,
          actions: JSON.stringify(obj.actions),
          notes: JSON.stringify(obj.notes),
          results: JSON.stringify(obj.results),
          values: JSON.stringify(formEntryValues),
          client_form_id: this.formTarget.dataset.formId,
          client_form_version_id: this.formTarget.dataset.formVersionId,
          client_id: obj.client_id,
          origin: data.origin,
          state_params: this.stateParamsValue,
          preview: this.previewValue,
        }

        return this.sendPostRequest(myData)
      }
      
      data.state_params = this.stateParamsValue
      data.preview = this.previewValue

      return this.sendPostRequest(data)
    }
  }

  sendPostRequest(data) {
    Rails.ajax({
      type: 'POST',
      url: '/api/form_entries',
      data: new URLSearchParams(data).toString(),
      success: (data) => {
        const confirmationDiv = this.resultsContainerTarget
        
        confirmationDiv.innerHTML = ''
        confirmationDiv.innerHTML += data.html
        confirmationDiv.classList.remove('d-none')
      },
      error: (err) => {
        alert(err)
      }
    })
  }

  updateSliderValue(event) {
    let rangeInput = event.currentTarget.dataset.rangeInputId
    let val = document.getElementById(`client_form_${rangeInput}_preview`).value
    let result = document.getElementById(`selected_value_for_${rangeInput}`)
    result.innerHTML = `: ${val}`
  }

  resetForm(event) {
    event.preventDefault()
    const form = document.querySelector(`#form_previewer_${event.currentTarget.dataset.formId}`)

    form.reset()
    this.resultsContainerTarget.classList.toggle('d-none', true)
  }

  getDataFromTheForm() {
    return getFormData(this.formTarget)
  }

  constructCalculator() {
    return {
      settings: JSON.parse(this.settingsTarget.value),
      inputs: JSON.parse(this.inputsTarget.value),
      operations: JSON.parse(this.operationsTarget.value),
      notes: JSON.parse(this.notesTarget.value),
      actions: JSON.parse(this.actionsTarget.value),
      results: JSON.parse(this.resultsTarget.value)
    }
  }

  runCalculator(calculator) {
    calculator.run()

    return {
      client_id: this.clientIdTarget.value,
      results: calculator.processedResults,
      actions: calculator.processedActions,
      notes: calculator.processedNotes,
      settings: calculator.settings
    }
  }

  // This is very hacky, and it only works with presence: true validation.
  // It is a starting point that I can build on top of to set constraints from the admin UI,
  // and use them here.
  validateInputs() {
    this.inputTargets.map((input) => {
      if (input.value === '') {
        // get our validate hash
        let constr = this.constraints.get(input.dataset.name)
        
        // validate lib doesn't really validate if value is empty string;
        // this is why we are setting the value to null, but checking if the input.value === "" above
        let errors = validate.single(null, constr)
        this.showErrorsForInput(input, errors)
      } else {
        this.showErrorsForInput(input, null)
      }
    })
  }

  showErrors(errors) {
    for (let input of this.inputTargets) {
      this.showErrorsForInput(input, errors[input.dataset.name])
    }
  }

  showErrorsForInput(input, errors) {
    this.clearErrors(input)
    if (errors) {
      input.parentElement.classList.add('has-error')
      this.insertErrorMessages(input, errors)
    } else {
      input.parentElement.classList.remove('has-error')
      input.parentElement.classList.add('has-success')
    }
  }

  clearErrors(input) {
    if (document.getElementById(`error_${input.dataset.name}`) != null) {
      document.getElementById(`error_${input.dataset.name}`).remove()
    }
  }

  insertErrorMessages(input, errors) {
    let html = document.createElement('div')
    html.innerHTML = errors.join(' ')
    html.id = `error_${input.dataset.name}`
    html.classList.add('small')
    input.after(html)
  }

  setConstraints() {
    this.constraints = new Map

    this.inputTargets.map((input) => {
      this.constraints.set(input.dataset.name, JSON.parse(input.dataset.validate))
    })
  }
}
