import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['button']

  checkbox_clicked() {
    this.buttonTarget.classList.toggle('disabled')
  }

}
