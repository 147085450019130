import { Controller } from '@hotwired/stimulus'

// Exists just to set the active state on the dropdown trigger when selecting
// any child element.
export default class extends Controller {
  #mutationObserver

  static targets = ['trigger', 'menu']

  connect() {
    super.connect()

    this.#mutationObserver =
      new MutationObserver(() => {
        if (this.menuTarget.matches(':has(.active)')) {
          this.setActive()
        }
        else {
          this.removeActive()
        }
      })

    this.#mutationObserver.observe(this.menuTarget, { attributes: true, childList: true, subtree: true })

    // And finally check to see if any children of this dropdown are the current
    // page, in which case set them as active.
    Array.from(this.menuTarget.querySelectorAll('.dropdown-item')).forEach((anchor) => {
      if (anchor.href == window.location) {
        anchor.classList.add('active')
      }
    })
  }

  disconnect() {
    this.#mutationObserver.disconnect()
  }

  setActive() {
    this.triggerTarget.classList.add('active')
  }

  removeActive() {
    this.triggerTarget.classList.remove('active')
  }

  toggleActive() {
    this.triggerTarget.classList.toggle('active')
  }

}
