import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['editor']

  connect() {
    window.addEventListener('trix-attachment-add', this.trixAttachmentAdd.bind(this))
  }

  trixAttachmentAdd(event) {
    if (event.target == this.editorTarget) {

      let attachment = event.attachment
      if (attachment.file) {
        this.uploadAttachment(attachment)
      }
    }
  }

  uploadAttachment(attachment) {
    let file = attachment.file
    let form  = new FormData
    form.append('Content-Type', file.type)
    form.append('support_document_image[file]', file)

    var xhr = new XMLHttpRequest
    xhr.open('POST', '/admin/support_document_images.json', true)
    xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken())

    xhr.upload.onprogress = function(event) {
      var progress = event.loaded / event.total * 100
      attachment.setUploadProgress(progress)
    }

    xhr.onload = function() {
      if (xhr.status === 201 || xhr.status === 200) {
        var data = JSON.parse(xhr.responseText)
        return attachment.setAttributes({
          url: data.image_url,
          href: data.image_url
        })
      }
    }

    return xhr.send(form)
  }

}
